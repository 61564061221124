.heroSection {
    position: relative;
    margin: -10vw 0 5vw 0;
    padding: 20vh 6vw 30vh 6vw;
    height: 130vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    gap: 0;
    background: url("/public/images/Mask_group.webp") no-repeat center bottom 0 / 200% 100vh,
    url("/public/images/Rectangle_36.webp") no-repeat center bottom 40vh / cover;
}

.imgWrapper {
    position: absolute;
    z-index: 60;
    width: 23vw;
    top: 12vw;
    right: 4vw;
}

.imgWrapper img {
    width: 100%;
    height: auto;
}

.memoriesWrapper {
    width: 22vw;
    transform: rotate(-17.717deg);
    margin: 25vh 0 0 -3vw;
    padding: 0;
}

.memoriesWrapper p {
    color: rgba(20, 20, 20, 0.70);
    text-align: center;
    font-family: Caveat-VariableFont, sans-serif;
    font-size: 3.9vw;
    font-style: normal;
    font-weight: 400;
    line-height: 90%;
    text-transform: lowercase;
}


.h1Wrapper {
    position: relative;
    width: 85%;
    display: flex;
    margin: 12vw 0;
    flex-wrap: wrap;
}

h1 {
    font-size: 5.5vw;
    line-height: 6.2vw;
    width: 100%;
    position: relative;
    text-align: left;
    color: #141414;
    font-family: "Inter", sans-serif;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
    background-image: linear-gradient(transparent calc(100% - 1px), rgba(128, 128, 128, 0.3) 1px),
                        linear-gradient(to right, transparent 10vw, transparent);
    background-size: 100% 6.2vw, 100% 6.2vw;
    background-repeat: repeat-y;
    background-position: 0 calc(100% - 1px), 0 0;
    font-weight: 300;
}

.recipient {
    position: absolute;
    top: 4.2vw;
    height: 2vw;
    left: 0;
    padding-right: 1vw;
    z-index: 10;
    color: rgba(20, 20, 20, 0.50);
    font-family: Inter, sans-serif;
    font-size: 1.2vw;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
}

.mrLeft {
    margin-left: 10vw;
}


@media (max-width: 1200px) {

    .heroSection {
        background: url("/public/images/Mask_group.webp") no-repeat center bottom 0 / 400% 100vh,
        url("/public/images/Rectangle_36.webp") no-repeat center bottom 40vh / cover;
    }


    .memoriesWrapper p {
        color: rgba(20, 20, 20, 0.70);
        text-align: center;
        font-family: Caveat-VariableFont, sans-serif;
        font-size: 3.9vw;
        font-style: normal;
        font-weight: 400;
        line-height: 90%;
        text-transform: lowercase;
    }

    .imgWrapper {
        position: absolute;
        z-index: 1300;
        width: 25vw;
        top: 11vw;
        right: 4vw;
    }

    .recipient {
        position: absolute;
        top: 3vw;
        height: 3vw;
        font-size: 1.6vw;
    }

    .memoriesWrapper {
        width: 20vw;
        margin: 10vw 0 0 -3vw;
        padding: 0;
    }

    .memoriesWrapper p {
        font-size: 3.8vw;
    }

    h1 {
        font-size: 4.9vw;
        line-height: 5.5vw;
        background-image: linear-gradient(transparent calc(100% - 1px), rgba(128, 128, 128, 0.5) 1px),
        linear-gradient(to right, transparent 13vw, transparent);
        background-size: 100% 5.55vw, 100% 5.55vw;
        background-repeat: repeat-y;
        background-position: 0 calc(100% - 3px), 0 0;
    }

    .mrLeft {
        margin-left: 13vw;
    }
}


@media (max-width: 768px) {

    .heroSection {
        background: url("/public/images/Mask_group.webp") no-repeat center bottom 0 / 500% 100vh,
        url("/public/images/Rectangle_36.webp") no-repeat center bottom 40vh / cover;
    }

    .h1Wrapper {
        position: relative;
        width: 100%;
        display: flex;
        margin: 5vh 0;
        flex-wrap: wrap;
    }

    .recipient {
        position: absolute;
        top: 2.5vw;
        height: 2.2vw;
        font-size: 2vw;
    }


    h1 {
        font-size: 4.9vw;
        line-height: 5.5vw;
        background-image: linear-gradient(transparent calc(100% - 1px), rgba(128, 128, 128, 0.5) 1px),
        linear-gradient(to right, transparent 15vw, transparent);
        background-size: 100% 5.55vw, 100% 5.55vw;
        background-repeat: repeat-y;
        background-position: 0 calc(100% - 3px), 0 0;
    }

    .mrLeft {
        margin-left: 15vw;
    }

    .imgWrapper {
        margin: 0;
        width: 100%;
        position: static;
        z-index: 1;
    }

    .imgWrapper img {
        width: 100%;
        height: auto;
    }

    .memoriesWrapper {
        width: 30vw;
    }

    .memoriesWrapper p {
        font-size: 36px;
    }
}


@media (max-width: 480px) {

    .heroSection {
        background: url("/public/images/Mask_group.webp") no-repeat center bottom 0 / 600% 100vh,
        url("/public/images/Rectangle_36.webp") no-repeat center bottom 40vh / cover;
    }

    .memoriesWrapper {
        margin-top: 10vh;
        width: 40vw;
    }

    .memoriesWrapper p {
        font-size: 7.5vw;
    }

    .h1Wrapper {
        margin: 5vh 0;
    }

    h1 {
        font-size: 5.6vw;
        line-height: 30px;
        background-image: linear-gradient(transparent calc(100% - 1px), gray 1px);
        background-size: 100% 31px;
        background-repeat: repeat-y;
        background-position: 0 calc(100% - 1px);
    }

    h1 {
        font-size: 5.6vw;
        line-height: 30px;
        background-image: linear-gradient(transparent calc(100% - 1px), rgba(128, 128, 128, 0.5) 1px),
        linear-gradient(to right, transparent 20vw, transparent);
        background-size: 100% 30px, 100% 30px;
        background-repeat: repeat-y;
        background-position: 0 calc(100% - 1px), 0 0;
    }

    .recipient {
        font-size: 10px;
    }

    .mrLeft {
        margin-left: 20vw;
    }

}


@media (max-width: 350px) {

    h1 {
        background-image: linear-gradient(transparent calc(100% - 1px), rgba(128, 128, 128, 0.5) 1px),
        linear-gradient(to right, transparent 25vw, transparent);
    }

    .recipient {
        position: absolute;
        top: 15px;
        height: 16px;
    }

    .mrLeft {
        margin-left: 25vw;
    }
}









