.footer {
    padding: 10vw 6vw 3vw 6vw;
    width: 100%;
    box-sizing: border-box;
    background:
            url("/public/images/Mask_group.webp") no-repeat center top / 200% 100vh;
}

.flexWrapper1 {
    display: flex;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.flexWrapper2 {
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    margin: 5vw 0 0 0;
}

.flexWrapper3 {
    width: 18%;
    display: flex;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    gap: 1.5vw;
}

.flexWrapper2 svg {
    width: 50px;
}

.footerParagraph {
    color: rgba(20, 20, 20, 0.40);
    font-family: Inter, sans-serif;
    font-size: 1.2vw;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    width: 10vw;
    padding: 0;
}

.svgWrapper {
    width: 30vw;
}

.svgWrapper svg,
.svgCity svg {
    width: 100%;
}

.diver {
    mix-blend-mode: darken;
}

.svgCity {
    width: 18vw;
}

.menuFooter {
    display: flex;
    gap: 3vw;
}

.menuFooter a {
    font-family: Inter, sans-serif;
    text-decoration: none;
    color: #141414;
    text-transform: uppercase;
    font-size: 1.25vw;
}

.sendLetterModal {
    color: #141414;
    font-family: Inter, sans-serif;
    font-size: 1.25vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    border: none;
    background: none;
    cursor: pointer;
}


@media (max-width: 1440px) {
    .footer {
        background:
                url("/public/images/Mask_group.webp") no-repeat center top / 300% 100vh;
    }
}

@media (max-width: 1200px) {
    .footer {
        background:
                url("/public/images/Mask_group.webp") no-repeat center top / 400% 100vh;
    }
}

@media (max-width: 768px) {

    .footer {
        background:
                url("/public/images/Mask_group.webp") no-repeat center top / 500% 100vh;
    }
    .footer {
        padding: 30vw 4vw 10vw 4vw;
        background-size: 400% 120%;
    }

    .flexWrapper1 {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 30px;
    }

    .flexWrapper2 {
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
        margin: 15vw 0 0 0;
    }
    .footerParagraph {
        font-size: 21px;
        width: 60%;
        margin: 0 20%;
        text-align: center;
    }

    .svgWrapper {
        width: 240px;
    }

    .svgWrapper svg,
    .svgCity svg {
        width: 100%;
        height: 100%;
    }


    .flexWrapper3 {
        width: 100%;
        flex-direction: column;
        gap: 20px;
        margin: 70px 0 0 0;
    }
    .svgCity {
        width: 150px;
    }

    .menuFooter {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;
        margin: 30px 0 0 0;
    }

    .menuFooter a {
        font-size: 16px;
    }

    .sendLetterModal {
        font-size: 16px;
    }
}



