.selectContainer {
    position: relative;
    display: inline-block;
    cursor: pointer;
    margin-right: 5vw;
}

.selectedOption {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #141414;
    font-family: "Inter", sans-serif;
    font-size: 18px;
    font-weight: 400;
    text-transform: uppercase;
    padding: 10px;
}

.selectedOption svg {
    margin-left: 10px;
}

.dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 1000;
    list-style: none;
    margin: 0;
    padding: 0;
}

.option {
    color: #7D7D7D;
    font-family: "Inter", sans-serif;
    font-size: 18px;
    font-weight: 400;
    text-transform: uppercase;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
}


.option:focus,
.option:active {
    outline: none;
}

.svg {
    display: inline-block;
}

.svgOpen,
.svgClosed {
    margin-left: 10px;
}

@media (max-width: 1199px) {
    .selectedOption {
        font-size: 16px;
    }

    .option {
        font-size: 16px;
    }
}






