body{
    padding: 0;
    margin: 0;
}
@font-face {
    font-family: 'Caveat-VariableFont';
    src: url('./fonts/Caveat-VariableFont_wght.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.noScroll {
    overflow: hidden;
}