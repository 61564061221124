.modalOverlay {
    position: fixed;
    z-index: 1500;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: white;
    display: flex;
}

.modalContent {
    position: relative;
    width: 100%;
    background: white;
    padding: 4vw 6vw;
    overflow-y: auto;
    max-height: 100%;
    margin-top: env(safe-area-inset-top);
    margin-bottom: env(safe-area-inset-bottom);
}

.stickyContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 4vw;
    left: 50%;
    transform: translate(-51%);
    padding: 2vw;
    margin:2vw 0;
    width: 85%;
    box-sizing: border-box;
    z-index: 1300;
    background: url("/public/images/form.webp") top center;
}

.sticky {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translate(-51%);
    padding: 2vw;
    margin:0 0;
    width: 85%;
}


.stickyContainer > * {
    position: relative;
    z-index: 1;
}


.closeModal {
    background: none;
    border: none;
    color: #141414;
    font-family: Inter, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    text-transform: uppercase;
    border-bottom: 1px solid #141414;
    padding: 0;
    margin: 0;
    cursor: pointer;
}

.closeModalForm{
    position: absolute;
    z-index: 1300;
    top: 9vw;
    right: 10vw;
    cursor: pointer;
    background: none;
    border: none;
    color: #141414;
    font-family: Inter, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    text-transform: uppercase;
    border-bottom: 1px solid #141414;
    padding: 0;
    margin: 0;
}

.customLikeButton {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    color: rgba(20, 20, 20, 0.50);
    text-align: right;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
    padding: 0;
    margin: 0;
    gap: 10px;
}



@media (max-width: 1199px){
    .stickyContainer {
        padding: 2vw 7vw;
    }

    .sticky {
        padding: 2vw 7vw;
    }
    .closeModal {
        font-size: 16px;
    }
    .closeModalForm {
        font-size: 16px;
    }
    .customLikeButton {
        font-size: 12px;
    }
}


@media (max-width: 479px) {
    .modalContent {
        padding: 0 0;
    }
    .stickyContainer {
        width: 90%;
        padding: 3vw 5vw;
    }
    .sticky {
        width: 90%;
        padding: 3vw 5vw;
    }
}
