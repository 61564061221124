.donatesInfoContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}
.donateHeader {
    padding-top: 3vw;
    width: 45vw;
}

.donateHeader h2 {
    color: #141414;
    font-size: 2.7vw;
    font-weight: 400;
    line-height: 128%;
    font-family: "Inter", sans-serif;
    margin: 0;
}

.donateHeader h2 span {
    color: rgba(20, 20, 20, 0.27);
    font-weight: 400;
    line-height: 128%;
}

.donateTextContainer {
    width: 45vw;
    padding: 4vw 0;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;

}

.donateTextContainer > p {
    width: 45%;
    color: #141414;
    font-size: 1.2vw;
    font-weight: 400;
    line-height: 140%;
    font-family: "Inter", sans-serif;
}

.donText {
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

}

.donText p {
    color: #141414;
    font-size: 1.2vw;
    font-weight: 400;
    line-height: 140%;
    font-family: "Inter", sans-serif;
}

.donText a {
    display: flex;
    padding: 17px 42px;
    justify-content: center;
    align-items: center;
    gap: 0.5vw;
    background: #000;
    color: #FFF;
    font-family: "Inter", sans-serif;;
    font-size: 1.25vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    border: none;
    cursor: pointer;
    box-sizing: border-box;
}
@media (max-width: 1200px) {
    .donateTextContainer {
        width: 45vw;
        margin: auto;
        padding: 4vw 0;
        display: flex;
        flex-direction: column;
        gap: 4vw;
    }

    .donateHeader h2 {
        font-size: 2.8vw;
    }

    .donateTextContainer > p {
        width: 45vw;
        font-size: 16px;

    }

    .donText {
        width: 45vw;
        gap: 5vw;
    }

    .donText p {
        font-size: 16px;
    }

    .donText a {
        font-size: 16px;
    }

}

@media (max-width: 768px) {
    .donateHeader {
        width: 100%;
    }

    .donateHeader h2 {
        font-size: 20px;
        margin: 0;
        padding: 0;
    }

    .donateTextContainer {
        width: 100%;
        margin-top: 3vh;
    }

    .donateTextContainer p {
        width: 100%;
    }

    .donText {
        width: 100%;
    }
    .donText a{
        width: 100%;
    }


}
