.ukrSection {
    position: relative;
    width: 100%;
    height: auto;
    margin: 0;
    padding: 20vw 6vw 5vw 6vw;
    background:
            url("/public/images/Mask_group.webp") no-repeat center top / 200% 100vh,
            url("/public/images/Rectangle_36.webp") no-repeat center top 30vh / cover;
    box-sizing: border-box;
}



.mapContainer {
    display: grid;
    grid-template-columns: 18vw 60vw;
    grid-template-rows: auto;
    gap: 2vw;
}
.map {
    position: relative;
    width: 70vw;
}

.region-path {
    cursor: pointer;
}
.infoWindow {
    position: fixed;
    padding: 20px;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.2);
    z-index: 1000;
    pointer-events: none;
    width: 25vw;
    font-family: Inter, sans-serif;
    transition: transform 0.2s ease-in-out;
}
.infoParagraph{
    margin-top: 20px;
    color: #141414;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.infoAddress{
    color: rgba(20, 20, 20, 0.50);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
}
.infoCount{
    display: flex;
}
.infoCount p{
    margin-left: 15px;
    color: rgba(20, 20, 20, 0.50);

    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
}
.mapInfo h2{
    color: #141414;
    font-family: Inter, sans-serif;
    font-size: 21px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
}
.mapInfo p{
    color: rgba(20, 20, 20, 0.90);
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
}

.hovered {
    fill: #141414;
    stroke: #141414;
}


@media (min-width: 1500px) {

    .ukrSection {
        background:
                url("/public/images/Mask_group.webp") no-repeat center top / 300% 100vh,
                url("/public/images/Rectangle_36.webp") no-repeat center top 30vh / cover;
    }
    .mapInfo h2 {
        font-size: 28px;
    }

    .mapInfo p{
        font-size: 21px;
    }
}

@media (max-width: 1200px) {

    .ukrSection {
        background:
                url("/public/images/Mask_group.webp") no-repeat center top / 400% 100vh,
                url("/public/images/Rectangle_36.webp") no-repeat center top 30vh / cover;
        padding: 30vw 6vw 5vw 6vw;
    }
    .mapContainer {
        display: flex;
        flex-direction: column;
        gap: 2vw;
    }

    .mapInfo{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }

    .mapInfo h2{
        width: 20vw;
        margin: 0;
    }
    .map {
        width: 100%;
        margin: 5vw 0;
    }
}

@media (max-width: 768px) {
    .ukrSection {
        background:
                url("/public/images/Mask_group.webp") no-repeat center top / 500% 100vh,
                url("/public/images/Rectangle_36.webp") no-repeat center top 30vh / cover;
    }
    .mapContainer {
        display: none;
    }

}

