.aboutSection {
    width: 100%;
    position: relative;
    padding: 0 6vw;
    box-sizing: border-box;
}

.aboutSection h2 {
    color: #141414;
    font-family: Inter, sans-serif;
    font-size: 2.8vw;
    font-style: normal;
    font-weight: 400;
    line-height: 128%;
}

.firstWord::first-letter {
    margin-left: 23vw;
}

.colorBeige {
    color: rgba(20, 20, 20, 0.27);
}

.therapy {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.letterWrite {
    color: #141414;
    font-family: Inter, sans-serif;
    font-size: 1.2vw;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    margin-left: 23vw;
    width: 23vw;
}

.imgWrapper5 {
    width: 17vw;
    position: absolute;
    left: 7vw;
    top: 45vw;
    transform: rotate(-6.307deg);
    flex-shrink: 0;
}

.imgWrapper5 svg {
    width: 3.7vw;
    margin-right: 20px;
}

.imgWrapper6 {
    width: 30vw;
    position: absolute;
    right: 8vw;
    top: 20vw;
    transform: rotate(-6.304deg);
    flex-shrink: 0;
}

.imgWrapper6 svg {
    width: 3.45vw;
}

.imgWrapper2 {
    width: 23vw;
    position: absolute;
    left: 22vw;
    bottom: 0;
    transform: rotate(7.93deg);
    flex-shrink: 0;
}

.imgWrapper2 svg {
    width: 3.7vw;
}

.imgWrapper6 .impParagraph {
    width: 40%;
    margin-right: 20%;
}

.imgWrapper2 .impParagraph {
    width: 60%;
    margin-right: 10%;
}

img {
    width: 100%;
}

.impParagraph {
    color: rgba(20, 20, 20, 0.70);
    font-family: Caveat-VariableFont, sans-serif;;
    font-size: 1.25vw;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
}

.blockFlex {
    display: flex;
    gap: 1.2vw;
    margin: 1vw 0;
}

.deliveryMG {
    margin: 8vw 2vw 3.5vw 23vw;
}

.marginLeft {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-left: 23vw;
}

.sendLetterModal {
    color: #141414;
    font-family: Inter, sans-serif;
    font-size: 1.25vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    cursor: pointer;
    background: none;
    border: none;
    border-bottom: 1px solid #141414;
    padding: 0 0 5px 0;
    margin: 2.5vw 0 5vw 46vw;
}


@media (max-width: 1024px) {
    .aboutSection {
        padding: 0 4vw;
    }

    .letterWrite {
        font-size: 1.6vw;
        width: 36vw;
    }

    .deliveryMG {
        margin: 58vw 2vw 5vw 23vw;
    }

    .imgWrapper5 {
        top: 55vw;
    }

    .imgWrapper6 {
        right: 8vw;
        top: 60vw;
    }

    .imgWrapper2 {
        width: 30vw;
        left: 22vw;
        bottom: 55vw;
    }

    .imgWrapper2 svg {
        width: 3.7vw;
    }

    .imgWrapper2 .impParagraph {
        width: 50%;
    }

    .marginLeft {
        margin-left: 0;
    }

    .sendLetterModal {
        font-size: 1.6vw;
        padding: 0 0 5px 0;
        margin: 2.5vw 0 5vw 23vw;
    }

}


@media (max-width: 480px) {
    .aboutSection {
        padding: 0 6vw;
    }

    .aboutSection h2 {
        font-size: 20px;
    }

    .firstWord::first-letter {
        margin-left: 0;
    }

    .letterWrite {
        font-size: 16px;
        margin-left: 0;
        width: 100%;
        margin-top: 30px;
    }

    .imgWrapper5 {
        display: flex;
        flex-direction: column-reverse;
        width: 100%;
        position: static;
        transform: rotate(0);
    }

    .imgWrapper5 svg {
        width: 65px;
        transform: scaleX(-1) rotate(180deg);
    }

    .imgWrapper6 {
        width: 100%;
        margin-top: 5vh;
        position: static;
        transform: rotate(0);
    }

    .imgWrapper6 svg {
        width: 36px;
    }

    .imgWrapper2 {
        width: 100%;
        position: static;
        transform: rotate(0);
    }

    .imgWrapper2 svg {
        width: 50px;
    }

    .blockFlex {
        display: flex;
        justify-content: space-between;
        gap: 20px;
        margin: 10px 10px 10px 0;
    }

    .imgWrapper6 .impParagraph {
        width: 100%;
        margin-right: 0;
    }

    .imgWrapper2 .impParagraph {
        width: 100%;
    }

    img {
        width: 100%;
    }

    .impParagraph {
        font-size: 18px;
    }

    .deliveryMG {
        margin: 8vw 0 3.5vw 0;
    }

    .marginLeft {
        margin-left: 0;
    }

    .sendLetterModal {
        font-size: 4.8vw;
        border-bottom: 1px solid #141414;
        padding: 0 0 5px 0;
        margin: 40px auto 40px 20vw;
    }
}

