.lettersPage {
    z-index: 10;
    width: 100%;
    margin-top: -10vw;
    padding: 13vw 6vw 10vw 6vw;
    box-sizing: border-box;
    background:
            url("/public/images/Mask_group.webp")  no-repeat center bottom 5vw/ 120% 50vh,
            url("/public/images/Rectangle_36.webp")  no-repeat center bottom 40vh / cover,
            #E1DFD9;
}

@media (max-width: 479px) {

}

@media (max-width: 1199px) {
    .lettersPage {
        margin-top: -10vw;
        padding: 13vw 6vw 15vw 6vw;
        box-sizing: border-box;
        background:
                url("/public/images/Mask_group.webp")  no-repeat center bottom 5vw/ 120% 50vh,
                url("/public/images/Rectangle_36.webp")  no-repeat center bottom 40vh / cover,
                #E1DFD9;
    }
}

@media (min-width: 1441px) {

}

