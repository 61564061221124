.letterContainer {
    position: relative;
    width: 100%;
    padding: 4vw;
    box-sizing: border-box;
    background:
            url("/public/images/form.webp")top center;
}
.underlineParagraph {
    text-indent: 50px;
}

.letterContainer > * {
    position: relative;
    z-index: 1;
}



.contentWrapper {
    padding: 6vw 15vw 10vw 15vw;
}

.letterContainer h2{
    color: #141414;
    font-family: "Inter", sans-serif;
    font-size: 4.7vw;
    font-style: normal;
    font-weight: 300;
    line-height: 110%;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
}
.imgWrapper {
    width: 100%;
    margin: 4.5vw 0;
    height: auto;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 2vw;
}

.tagsWrapper {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: left;
    margin: 0;
    padding: 0;
    gap: 20px;
}

.tagsWrapper h4{
    color: #141414;
    font-family: Inter, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
}

.tagsBlock {
    display: flex;
    flex-wrap: wrap;
    gap: 14px;
}

.tags{
    color: #FFF;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 100px;
    background: #000;
    display: flex;
    padding: 5px 15px;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.paragraphWrapper{
    width: 100%;
    box-sizing: border-box;
    margin: 5vw 0;
}

.paragraphWrapper p {
    color: #141414;
    font-family: Inter, sans-serif;
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: 46px;
}

.underlineParagraph {
    position: relative;
    line-height: 46px;
    background-image: linear-gradient(transparent calc(100% - 1px), gray 1px);
    background-size: 100% 46px;
    background-repeat: repeat-y;
    background-position: 0 calc(100% - 5px);
}

@media (min-width: 1441px){}



@media (max-width: 1199px){
    .contentWrapper {
        padding: 6vw 6vw 10vw 6vw;
    }
    .tagsWrapper h4 {
        font-size: 16px;
    }
    .tagsBlock {
        gap: 10px;
    }
    .paragraphWrapper p {
        font-size: 16px;
        line-height: 35px;
    }

    .underlineParagraph {
        position: relative;
        line-height: 35px;
        background-image: linear-gradient(transparent calc(100% - 1px), gray 1px);
        background-size: 100% 35px;
        background-repeat: repeat-y;
        background-position: 0 calc(100% - 5px);
    }
}







@media (max-width: 479px) {
    .contentWrapper {
        padding: 25vw 6vw 20vw 6vw;
    }

    .underlineParagraph {
         position: relative;
         line-height: 35px;
         background-image: linear-gradient(transparent calc(100% - 1px), gray 1px);
         background-size: 100% 35px;
         background-repeat: repeat-y;
         background-position: 0 calc(100% - 5px);
     }
    .letterContainer h2 {
        font-size: 30px;
    }
}

