.thankContainer {
    position: relative;
    margin: 0;
    width: 100%;
    background: #E1DFD9;
    padding: 5vw;
    box-sizing: border-box;
    display: flex;
}

.thankContainer::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('/public/images/form.webp') lightgray 50% / cover no-repeat;;
    opacity: 0.5;
    z-index: 1;
}

.thankContainer > * {
    position: relative;
    z-index: 2;
}

.thankBlock {
    max-width: 30vw;
}

.thankBlock h2 {
    color: #141414;
    font-family: 'Inter', sans-serif;
    font-size: 3.3vw;
    font-style: normal;
    font-weight: 300;
    line-height: 110%;
    text-transform: uppercase;
    margin: -1vw 0 0 0
}

.thankBlock p {
    margin-top: 7.5vw;
    color: #141414;
    font-family: 'Inter', sans-serif;
    font-size: 2.25vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    max-width: 55%;
}

.thankBlock a {
    display: block;
    max-width: 65%;
    text-transform: lowercase;
    word-wrap: break-word;
    color: #6861FF;
    font-family: 'Inter', sans-serif;
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    margin-top: 2vw;

}

.donatComponent {
    max-width: 45vw;
    margin-top: 6.5vw;
}
@media (max-width: 1200px) {
    .thankContainer {
        flex-direction: column;
    }
    .thankBlock {
        max-width: 40vw;
    }
    .thankBlock h2{
        margin: 0;
    }
    .thankBlock p{
        max-width: 45%;
    }
    .thankBlock a {
        max-width: 100%;
    }
    .donatComponent {
        max-width: 50vw;
        margin-left: -2.5vw;
    }


}

@media (max-width: 768px) {
    .thankContainer{
        height: 100vh;
        justify-content: space-around;
    }
    .thankBlock {
        max-width: 100%;
    }
    .thankBlock h2{
        font-size: 7.8vw;
    }
    .thankBlock p{
        color: rgba(20, 20, 20, 0.40);
        max-width: 80%;
        font-size: 4.7vw;
    }
    .thankBlock a{
        max-width: 80%;
        font-size: 16px;
        margin-top: 7.5vw;
    }
    .donatComponent{
        max-width: 100%;
        margin-left: 0;
    }
}