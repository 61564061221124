.showLettersComponent {
    padding: 0 0 5vw 0;
    margin: 0;
    background: url("/public/images/Rectangle_36.webp") no-repeat center bottom 30vh / cover,
    url("/public/images/Mask_group.webp") no-repeat center bottom / 200% 100vh;
    box-sizing: border-box;
}

.carouselContainer {
    position: relative;
    overflow: hidden;
    width: 90%;
    padding: 0 5%;
    height: auto;
    user-select: none;
    -webkit-user-drag: none;
    -webkit-touch-callout: none;
}

.sliderWrapper {
    position: relative;
    width: 100%;
    min-height: 450px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sliderItem {
    position: absolute;
    top: 0;
    width: 45vw;
    height: auto;
    transition: all 0.5s ease-in-out;
}

.prevArrow,
.nextArrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 3;
    user-select: none;
    width: 30px;
}

.prevArrow {
    left: 0;
}

.nextArrow {
    right: 0;
}

.h2send {
    color: #141414;
    font-family: Inter, sans-serif;
    font-size: 1.4vw;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    padding: 40px;
    text-align: center;
    margin: 0;
}

.buttonPageWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.buttonPage {
    color: #141414;
    font-family: Inter, sans-serif;
    font-size: 1.1vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    margin: 5vw auto;
    cursor: pointer;
    background: none;
    border: none;
    border-bottom: 1px solid #141414;
    padding: 0 0 5px 0;
}


@media (max-width: 1440px) {
    .showLettersComponent {
        background: url("/public/images/Rectangle_36.webp") no-repeat center bottom 30vh / cover,
        url("/public/images/Mask_group.webp") no-repeat center bottom / 300% 100vh;
    }
}


@media (min-width: 1200px ) {
    .sliderWrapper {
        position: relative;
        width: 100%;
        min-height: 450px;
        display: flex;
        align-items: center;
        justify-content: center;
    }


}

@media (max-width: 1199px) {

    .showLettersComponent {
        background: url("/public/images/Rectangle_36.webp") no-repeat center bottom 30vh / cover,
        url("/public/images/Mask_group.webp") no-repeat center bottom / 400% 100vh;
    }

    .sliderWrapper {
        min-height: 320px;
    }

    .h2send {
        font-size: 21px;
    }

    .buttonPage {
        font-size: 18px;
    }
}


@media (max-width: 768px) {
    .showLettersComponent {
        padding: 0 0 30vw 0;
        background: url("/public/images/Rectangle_36.webp") no-repeat center bottom 30vh / cover,
        url("/public/images/Mask_group.webp") no-repeat center bottom / 500% 100vh;
    }

    .sliderWrapper {
        display: none;
    }

    .mobileList {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .mobileItem {
        width: 100%;
    }


    .buttonPage a {

        font-size: 18px;
    }

    .buttonPageWrapper {
        margin-top: 5vh;
    }

    .h2send {
        font-size: 21px;
    }
}





