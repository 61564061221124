.form {
    position: relative;
    margin: 0;
    width: 100%;
    background: #E1DFD9;
    padding: 5vw;
    box-sizing: border-box;
}

.form::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('/public/images/form.webp')lightgray 50% / cover no-repeat;;
    opacity: 0.5;
    z-index: 1;
}

.form > * {
    position: relative;
    z-index: 2;
}

.h2Letter {
    color: #141414;
    font-family: Inter, sans-serif;
    font-size: 3.1vw;
    font-style: normal;
    font-weight: 300;
    line-height: 110%;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
}

.userInfo {
    width: 50vw;
}

.userGrid {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-content: space-between;
    align-items: flex-end;
    margin: 2vw 0 0 0;
    gap: 1vw;
}

.inputBlock {
    width: 100%;
    display: flex;
    align-content: flex-end;
    align-items: flex-end;
    justify-content: space-between;
}

.userInfo label,
.tagWrapper label {
    width: 25%;
    color: rgba(20, 20, 20, 0.50);
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
}

.userInfo input {
    width: 75%;
    background: none;
    border: none;
    border-bottom: 1px solid rgba(20, 20, 20, 0.20);
    color: #141414;
    font-family: "Inter Tight", sans-serif;
    font-size:32px;
    font-style: normal;
    font-weight: 300;
    line-height: 110%;
    text-transform: uppercase;
}

input:hover, input:focus, input:active {
    background: none;
    border: none;
    border-bottom: 1px solid rgba(20, 20, 20, 0.20);
    color: #141414;
    outline: none;
}

.notSelected {
    color: rgba(20, 20, 20, 0.40);
}


.selected {
    color: #141414;
}

select {
    width: 75%;
    background: none;
    border: none;
    border-bottom: 1px solid rgba(20, 20, 20, 0.20);
    font-family: "Inter Tight", sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 300;
    line-height: 110%;
    text-transform: uppercase;
    margin: 10px 0 0 0;
    box-shadow: none;
    outline: none;
}


select option {
    background: none;
    border-bottom: 1px solid rgba(20, 20, 20, 0.20);
    color: #141414;
    font-size: 16px;
}

select option:checked {
    background: none;
}

.imgWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 23vw;
    height: 15vw;
    border: 2px dashed rgba(0, 0, 0, 0.50);
    background: rgba(0, 0, 0, 0.05);
    margin: 0;
    padding: 0;
    cursor: pointer;
    font-family: "Inter", sans-serif;
}

.blockWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.customFileInput {
    color: #141414;
    font-family: "Inter", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 110%;
    cursor: pointer;
}


textarea {
    all: unset;
    width: 100%;
    min-height: 0;
    resize: none;
    box-sizing: border-box;
    color: #141414;
    font-family: Inter, sans-serif;
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: 46px;
}

.underlineParagraph {
    position: relative;
    line-height: 46px;
    background-image: linear-gradient(transparent calc(100% - 1px), gray 1px);
    background-size: 100% 46px;
    background-repeat: repeat-y;
    background-position: 0 calc(100% - 5px);
}


.textWrapper {
    margin: 2vw 0;
}

.tagWrapper {
    display: flex;
    flex-direction: column;
    gap: 2vw;
    margin: 1vw 0;

}

.tagButtons {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}


.tagButtons button {
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: none;
    display: flex;
    padding: 5px 15px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
}

.inactiveTag {
    background-color: #FFF;
    color: #111;
}

.activeTag {
    background: #111;
    color: white;
}

.checkBlock {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row-reverse;
    gap: 10px;
    margin: 30px 0;

}

.checkboxLabel {
    color: #141414;
    font-family: Inter, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
}

input[type="checkbox"] {
    width: 20px;
    height: 20px;
    margin: 0;
    appearance: none;
    border: 2px solid #141414;
    border-radius: 4px;
    background-color: white;
    cursor: pointer;
    position: relative;
}

input[type="checkbox"]:checked {
    background-color: #141414;
}

input[type="checkbox"]:checked::before {
    content: '✓';
    color: white;
    font-size: 16px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.errorMessage {
    color: #F00;
    font-family: Inter, sans-serif;
    font-size: 1.5vw;
    font-style: normal;
    font-weight: 400;
}

.validButton {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.validButton button {
    background: none;
    border: none;
    color: #141414;
    font-family: Inter, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    text-transform: uppercase;
    border-bottom: 1px solid #141414;
    padding: 0 0 5px 0;
    margin: 2vw 0 0 15vw;
    cursor: pointer;
}


@media (min-width: 1500px) {
    .userInfo {
        width: 40vw;
    }

    .userInfo label {
        width: 35%;
    }
}


@media (max-width: 1199px) {
    .blockWrapper {
        align-items: flex-start;
        flex-direction: column;
    }

    .userInfo {
        width: 100%;
    }

    .imgWrapper {
        margin-top: 5vw;
        width: 100%;
        height: 50vw;
        cursor: pointer;
    }

    .tagWrapper label {
        width: 100%;
    }

    .validButton button {
        font-size: 16px;
    }

    .errorMessage {
        font-size: 18px;
    }

    textarea {
        font-size: 18px;
    }
    select{
        font-size: 3vw;
    }
    .userInfo input,
    .tagWrapper input {
        font-size: 3vw;
    }

}


@media (max-width: 479px) {

    .h2Letter {
        font-size: 30px;
    }

    .blockWrapper {
        margin-top: 15vw;;
    }

    .h2Letter {
        width: 80%;
    }

    .userInfo label{
        font-size: 10px;
    }

    .tagButtons {
        margin: 20px 0;
    }

    .tagButtons button {
        font-size: 14px;
    }

    .checkboxLabel {
        font-size: 14px;
    }

    .userInfo input,
    .tagWrapper input {
        width: 100%;
        font-size: 18px;
        margin: 10px 0;
    }

    .inputBlock {
        display: block;
        margin: 10px 0 0;
        width: 100%;
    }

    select {
        width: 100%;
        font-size: 18px;
    }
    textarea {
        font-size: 16px;
        margin: 30px 0;
    }

    .errorMessage{
        font-size: 14px;
        line-height: 150%;
    }

    .validButton {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;
    }
    .validButton button{
        margin-left: 0;
    }

}


