.header {
    display: flex;
    align-items: center;
    background: url("/public/images/Rectangle_36.webp") no-repeat center / cover;
    justify-content: space-between;
    font-family: Arial, sans-serif;
    position: fixed;
    z-index: 50;
    width: 100%;
    height: 10vh;
    top: 0;
    padding-top: 2vw;
}


.menu {
    height: 100%;
    display: flex;
    align-items: center;
    gap: 3vw;
}
.menuLetter {
    height: 100%;
    display: flex;
    align-items: center;
    gap: 3vw;
}

.modal {
    display: none;
}

.emptyBlock {
    width: 30vw;
}

.menuButton {
    display: none;
}

.svg {
    height: 100%;
    margin-left: 6vw;
    width: 5vw;
}

a {
    font-family: Inter, sans-serif;
    text-decoration: none;
    color: #141414;
    font-size: 18px;
    text-transform: uppercase;
}

.sendLetter {
    background-color: black;
    color: white;
    padding: 17px 42px;
    margin-right: 6vw;
    font-size: 18px;
    cursor: pointer;
}



@media (max-width: 1300px) {
    .svg {
        margin-left: 5vw;
    }
    .sendLetter {
        margin-right: 5vw;
    }
}

@media (max-width: 1199px) {
    a {
        font-size: 1.6vw;
    }

    .sendLetter {
        font-size: 1.6vw;
    }

    .svg {
        margin-left: 4vw;
    }
    .sendLetter {
        background-color: black;
        color: white;
        padding: 16px 40px;
        margin-right: 4vw;
        font-size: 16px;
    }


}

@media (max-width: 850px) {

    .header {
        height: 5vh;
        padding-top: 3vh;
    }
    .svg {
        width: 64px;
    }
    .svg2 {
        width: 64px;
        margin-top: 20px;
    }

    .menu {
        display: none;
    }

    .sendLetter {
        display: none;
    }

    .modal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: white;
        z-index: 1000;
        display: flex;
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.3s ease, visibility 0.3s ease;
        box-sizing: border-box;
    }

    .modalContent {
        padding: 1.4vh 6vw 0 6vw;
        background-color: white;
        width: 100%;
        text-align: center;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        top: 0;
        left: 0;
        height: 100%;
        z-index: 999;
    }

    .modal.open {
        visibility: visible;
        opacity: 1;
    }

    .menuButton {
        display: block;
        background: none;
        border: none;
        cursor: pointer;
        position: relative;
        width: 50px;
        margin-right: 6vw;
        height: 14px;
        border-bottom: 1px solid #141414;
        border-top: 1px solid #141414;
    }



    .modalTop {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .modalNav {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .closeButton {
        background: none;
        border: none;
    }

    .modalNav a {
        color: #141414;
        text-align: left;
        font-family: Inter, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
    }

    .modalTop svg {
        padding-right: 0;
    }
    .sendLetterModal{
        background-color: #111111;
        color: #FFFFFF;
        margin: 45vh 0 20px 0 ;
        padding: 17px 42px;
    }
}








