.donateSection {
    width: 100%;
    position: relative;
    padding: 15vh 6vw;
    box-sizing: border-box;
}
.donateInfo{
    width: 100%;
}

.donGoal {
    width: 20%;
    position: absolute;
    top: 15vh;
    left: 6vw;
    color: #141414;
    font-family: "Inter", sans-serif;
    font-size: 1.5vw;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
}

.svgContainer {
    position: relative;
    width: 4.5vw;
    height: 9vw;
}

.svgContainer svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 4.5vw;
    height: 9vw;
}

.svgBlockOne {
    display: flex;
    gap: 3vw;
}

.svgBlockTwo {
    display: flex;
    gap: 3vw;
    padding-left: 6vw;
    position: relative;
}

.sumBlock {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hrn {
    position: absolute;
    bottom: 0;
    right: -6vw;
    color: #141414;
    font-family: "Inter", sans-serif;
    font-size: 1.5vw;
    font-style: italic;
    font-weight: 400;
    text-transform: uppercase;
}

.outerRectangle {
    margin-top: 3.5vw;
    width: 45vw;
    height: 2vw;
    flex-shrink: 0;
    border: 0.75px solid #000;
    position: relative;
    box-sizing: border-box;
    padding: 0 0.4vw;
}

.innerRectangle {
    height: 1.2vw;
    flex-shrink: 0;
    background: #141414;
    border: 0.75px solid #000;
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
}

.recWrap {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.collectedAmount {
    padding: 1vw 0 5vw 0;
    width: 45vw;
    margin: auto;
    color: #141414;
    font-family: "Inter", sans-serif;
    font-size: 1.5vw;
    font-style: italic;
    font-weight: 400;
    text-transform: uppercase;
}

.collectedAmount span {
    margin-left: 0.5vw;
}



@media (max-width: 1200px) {

}

@media (max-width: 768px) {

    .donateSection {
        width: 100%;
        position: relative;
        padding: 5vh 6vw;
    }

    .donGoal {
        top: 0;
        left: 45%;
        font-size: 21px;
    }

    .svgBlockOne {
        gap: 5vw;
    }

    .svgContainer {
        height: 16vw;
        width: 8.2vw;
    }

    .svgContainer svg {
        height: 16vw;
        width: 8.2vw;
    }

    .hrn {
        font-size: 4.8vw;
        right: -12vw;
    }

    .outerRectangle {
        width: 100%;
        margin: 3vh;
        height: 21px;
    }

    .innerRectangle {
        height: 16px;
        top: 49.5%;
    }

    .collectedAmount {
        width: 100%;
        font-size: 18px;
    }
    .sumBlock {
        margin-top: 5vh;
    }
}