.likeContainer {
    display: flex;
    align-items: center;
    gap: 8px;
}
.likeText{
    color: rgba(20, 20, 20, 0.50);
    text-align: right;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
}
.likeCount{
    color: rgba(20, 20, 20, 0.50);
    text-align: right;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
}

.likeButton {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
}

.likeButton:disabled {
    cursor: default;
}

.likeButton svg {
    transition: fill 0.3s ease;
}

@media (max-width: 479px) {
    .likeContainer {
        display: flex;
        align-items: center;
        flex-direction: column-reverse;
        gap: 0;
    }
}
@media (max-width: 1199px){
    .likeText{
        display: none;
    }
}
@media (min-width: 1441px){}