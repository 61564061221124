.homePage{
    background: #E1DFD9;
    width: 100%;
    box-sizing: border-box;
}

.no-scroll {
    overflow: hidden;
}

