.gridContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(5, auto);
    gap: 30px;
    margin-top: 27vh;
    box-sizing: border-box;
}

.nextButton {
    color: #141414;
    font-family: Inter, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    background: none;
    border: none;
    outline: none;
    text-decoration: none;
    border-bottom: 2px solid #141414;
    padding: 10px 0;
    cursor: pointer;
    display: block;
    margin: 8vw auto 10vw;
}





@media (max-width: 768px) {
    .gridContainer {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    .nextButton {
        margin: 60px auto 170px;
    }
}
@media(max-width: 1199px){
    .nextButton {
        margin: 8vw auto 23vw;
    }
}
@media (min-width: 1441px){
    .nextButton {
        margin: 10vw auto 19vw;
    }
}

