.tagFilterContainer{
    width: 100%;
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0;
    padding: 20vh 6vw 2vw 6vw;
    box-sizing: border-box;
    background: url("/public/images/Rectangle_36.webp") no-repeat center / cover;
    transition: padding-top 0.2s ease;
}
.filterContainer {
    display: flex;
    justify-content: space-between;
    padding: 2vw 0 2vw 0;
}

.filterHeader {
    color: #141414;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-size: 18px;
    cursor: pointer;
}
.svg{
    margin-left: 10px;
}
.sortList{
    width: 230px;
    display: flex;
    flex-direction: column;
    color: #141414;
    font-family: "Inter", sans-serif;
    font-size: 18px;
    font-weight: 400;
    gap: 14px;
    margin-right: 0;
    margin-left: auto;
    text-align: right;
    cursor: pointer;
}
.sortList li{
    list-style: none;
}



@media (min-width: 1441px){

}



@media (max-width: 1199px){
    .filterHeader {
        font-size: 16px;
    }
    .sortList {
        font-size: 16px;
    }


}
@media (max-width: 768px) {
    .filterContainer {
        padding: 5vw 0;
    }
    .tagFilterContainer {
        padding: 17vh 6vw 2vw 6vw;
    }

}

@media (max-width: 479px) {
    .filterHeader {
        font-size: 4vw;
    }

}










